<template>
	<v-container fluid class="pa-0">
		<v-toolbar dense elevation="1" :style="stickyTop">
			<v-toolbar-title> Interview template</v-toolbar-title>
			<v-spacer />
			<!-- ADD QUESTION -->
			<v-btn fab icon @click="openDialogAdd()">
				<v-icon> mdi-plus-circle-outline </v-icon>
			</v-btn>
			<v-dialog width="800" max-width="100vw" v-model="showDialogAdd">
				<AddQuestion :key="dialogAddKey" :templateId="template.id" v-on:closeDialogAdd="showDialogAdd = false"
					v-on:refreshList="fetchQuestions" />
			</v-dialog>
			<v-spacer />

			<v-btn @click="convertForTranslation" disabled>CONVERT FR</v-btn>
		</v-toolbar>
		<v-container>
			<v-data-iterator :items="questions" item-key="id" disable-pagination hide-default-footer single-expand
				:expanded="expanded">
				<template v-slot:default="{ items, isExpanded, expand }">
					<v-row justify="center">
						<v-col v-for="question in questions" :key="question.id"
							:cols="isExpanded(question) ? '10' : '5'">
							<QuestionCard :question="question" :template="template" :expanded="isExpanded(question)"
								v-on:toggleExpanded="() =>
			expand(question, !isExpanded(question))
			" class="mt-6" />
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
		</v-container>
	</v-container>
</template>
<script>
import { db } from "@/services/firebase";
import { collection, getDocs, updateDoc } from "firebase/firestore";
import QuestionCard from "@/components/QuestionCard";
import AddQuestion from "@/components/AddQuestion";

export default {
	name: "SurveyTemplates",
	components: { QuestionCard, AddQuestion },
	data() {
		return {
			dialogAddKey: 1,
			expanded: [],
			loading: false,
			templates: [],
			template: {},
			questions: [],
			showDialogAdd: false,
		};
	},
	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 1,
			};
		},
	},
	mounted() {
		this.fetchTemplates().then(() => {
			if (this.templates[0]) {
				this.template = this.templates[0];
				this.fetchQuestions();
			}
		});
	},
	methods: {
		async convertForTranslation() {

			alert("uncomment in method convertForTranslation: convertArrayForTranslation + convertFieldsForTranslation ;  attention: only run once!")
			return

			const convertArrayForTranslation = async () => {
				const querySnapshot = await getDocs(collection(db, '/surveyTemplates/TPL-001/questions'));
				const updates = querySnapshot.docs.map(async (doc) => {
					const results = doc.data().results.map(result => ({
						...result,
						resultText: {
							nl: result.resultText,
							fr: ''
						}
					}));
					return updateDoc(doc.ref, { results });
				})
				await Promise.all(updates);
			}

			const convertFieldsForTranslation = async () => {
				const querySnapshot = await getDocs(collection(db, '/surveyTemplates/TPL-001/questions'));
				const updates = querySnapshot.docs.map((doc) => {
					const question = doc.data.question()
					const title = doc.data().title;
					return updateDoc(doc.ref, {
						'question.nl': question,
						'question.fr': '',
						'title.nl': title,
						'title.fr': ''
					});
				});
				await Promise.all(updates);
			};

			try {
				//convertArrayForTranslation()
				//convertFieldsForTranslation()
			}
			catch (e) {
				console.error(e)
			}
		},
		async fetchTemplates() {
			this.loading = true;
			const cRef = collection(db, "surveyTemplates");
			const res = await getDocs(cRef);
			this.templates = res.docs.map((doc) => {
				let x = { ...doc.data(), id: doc.id };
				this.loading = false;
				return x;
			});
		},
		async fetchQuestions() {
			const cRef = collection(
				db,
				"surveyTemplates",
				this.template.id,
				"questions"
			);
			const res = await getDocs(cRef);
			this.questions = res.docs.map((doc) => {
				let x = { ...doc.data(), id: doc.id };
				return x;
			});
			this.questions.sort((a, b) => {
				return a.position - b.position;
			});
		},
		openDialogAdd() {
			this.dialogAddKey += 1;
			this.showDialogAdd = true;
		},
		toggleExpanded() {
			alert("jow");
			console.log(this.expanded);
		},
	},
};
</script>