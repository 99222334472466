<template>
	<v-card :elevation="expanded ? 10 : 0" outlined>
		<v-card-title v-show="!expanded" class="text-truncate">
			{{ question.position }}. {{ question.title['nl'] }}
		</v-card-title>
		<v-card-subtitle class="text-truncate" v-show="!expanded">
			{{ question.question['nl'] }}
		</v-card-subtitle>

		<v-toolbar flat dense :class="expanded ? 'grey lighten-5' : ''">
			<v-toolbar-items v-show="expanded">
				<v-btn icon color="primary" :disabled="updateActive"
					v-on:click.stop="beginUpdate()"><v-icon>mdi-pencil-outline</v-icon>
				</v-btn>
				<v-btn icon :disabled="!dataModified" v-on:click.stop="upsertQuestion()"
					color="green"><v-icon>mdi-check-outline</v-icon></v-btn>
				<v-btn icon color="red" :disabled="!updateActive"
					@click.stop="cancelUpdate()"><v-icon>mdi-close</v-icon></v-btn>
			</v-toolbar-items>

			<v-spacer />

			<v-tabs v-model="tab" centered v-show="expanded">
				<v-tab>NL</v-tab>
				<v-tab>FR</v-tab>
			</v-tabs>

			<v-spacer />

			<v-icon @click="$emit('toggleExpanded')">{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down"
				}}</v-icon>
		</v-toolbar>


		<v-divider />

		<!-- NL  -->
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<v-card-text v-show="expanded">
					<v-row>
						<v-col cols="1">
							<v-text-field v-model="question.position" label="Volgnr" :readonly="isReadOnlyNL"
								@input="dataModfied()" flat hide-details type="Number" />
						</v-col>
						<v-col cols="7">
							<v-text-field v-model="question.title['nl']" label="Titel" :readonly="isReadOnlyNL"
								@input="dataModfied()" flat hide-details />
						</v-col>
						<v-col cols="4">
							<cluster-selector @update:selectedCluster="setCluster" :readonly="isReadOnlyNL"
								:currentValue="question.cluster ? question.cluster.id : null" />

						</v-col>
						<v-col cols="12">
							<v-textarea label="Vraag" :readonly="isReadOnlyNL" v-model="question.question['nl']" flat
								@input="dataModfied()" hide-details />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4" v-for="result in question.results" :key="result.resultId">
							<v-textarea :label="result.label" class="mt-4" :readonly="isReadOnlyNL"
								v-model="result.resultText['nl']" flat @input="dataModfied()" />
						</v-col>
					</v-row>
				</v-card-text>
			</v-tab-item>
			<v-tab-item :value="1">
				<v-card-text v-show="expanded">
					<v-row>
						<v-col cols="1">
							<v-text-field v-model="question.position" label="Volgnr" readonly @input="dataModfied()"
								flat hide-details type="Number" />
						</v-col>
						<v-col cols="7">
							<v-text-field v-model="question.title['fr']" label="Titel FR" :readonly="isReadOnlyNL"
								@input="dataModfied()" flat hide-details />
						</v-col>

						<v-col cols="12">
							<v-textarea label="Vraag FR" :readonly="isReadOnlyNL" v-model="question.question['fr']" flat
								@input="dataModfied()" hide-details />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4" v-for="result in question.results" :key="result.resultId">
							<v-textarea :label="result.label + ' FR'" class="mt-4" :readonly="isReadOnlyNL"
								v-model="result.resultText['fr']" flat @input="dataModfied()" />
						</v-col>
					</v-row>
				</v-card-text>
			</v-tab-item>
		</v-tabs-items>

	</v-card>
</template>

<script>
import { updateDoc, doc } from "firebase/firestore";
import { db } from "@/services/firebase.js";
import ClusterSelector from "@/components/ClusterSelector.vue"
import { mapGetters } from "vuex";

export default {
	name: "QuestionCard",
	components: { ClusterSelector },
	props: {
		question: Object,
		template: Object,
		expanded: Boolean,
	},

	computed: {
		...mapGetters({
			userInfo: "session/userInfo",
		}),
		isReadOnlyNL() {
			return this.updateActive === false || this.userInfo.role === 'translator'
		}
	},
	data() {
		return {
			loading: false,
			updateActive: false,
			dataModified: false,
			originalQuestion: "",
			groupItems: [
				{
					text: "Creatieve kracht",
					value: "Creatieve kracht",
				},
				{
					text: "Prestatie kracht",
					value: "Prestatie kracht",
				},
				{
					text: "Prosociale kracht",
					value: "Prosociale kracht",
				},
				{
					text: "Dynamische kracht",
					value: "Dynamische kracht",
				},
				{
					text: "Mentale kracht",
					value: "Mentale kracht",
				},
			],
			tab: 0
		};
	},
	mounted() {
		this.originalQuestion = this.question.question;
	},
	methods: {
		beginUpdate() {
			this.updateActive = true;
		},
		cancelUpdate() {
			this.updateActive = false;
			this.dataModified = false;
			this.question.question = this.originalQuestion;
		},
		dataModfied() {
			this.dataModified = true;
		},
		async upsertQuestion() {
			await updateDoc(
				doc(
					db,
					"surveyTemplates",
					this.template.id,
					"questions",
					this.question.id
				),
				{
					...this.question
				}
			);
			this.updateActive = false;
			this.dataModified = false;
			this.$store.dispatch("session/showSuccess");
		},
		setCluster(_cluster) {
			this.question.cluster = { id: _cluster.id, name: _cluster.name }
			this.dataModfied()
		}
	},
};
</script>

<style>
</style>