<template>
	<v-card>
		<v-card-title>Vraag toevoegen</v-card-title>
		<v-card-text>
			<v-text-field v-model="question.position" type="number" label="Volgnr"></v-text-field>

			<cluster-selector />
			<v-text-field v-model="question.title" label="Titel"></v-text-field>
			<v-textarea v-model="question.question" label="Vraag"></v-textarea>
		</v-card-text>
		<v-card-actions>
			<v-btn plain @click="addQuestion">Bevestigen</v-btn>
			<v-spacer />
			<v-btn plain @click="$emit('closeDialogAdd')">Annuleren</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { db } from "@/services/firebase";
import { doc, collection, addDoc } from "firebase/firestore";
import ClusterSelector from "@/components/ClusterSelector.vue"
export default {
	name: "AddQuestion",
	components: { ClusterSelector },
	props: {
		templateId: String,
	},
	data() {
		return {
			question: {
				position: 0,
				title: "",
				question: "",
				cluster: {},
			},
		};
	},
	methods: {
		async addQuestion() {
			await addDoc(
				collection(db, "surveyTemplates", this.templateId, "questions"),
				{
					position: Number(this.question.position),
					title: this.question.title,
					question: this.question.question,
					cluster: this.question.cluster,
				}
			);
			this.$emit("refreshList");
			this.$emit("closeDialogAdd");
		},
		setCluster(_cluster) {
			this.question.cluster = { id: _cluster.id, name: _cluster.name }
			this.dataModfied()
		}
	},
};
</script>
