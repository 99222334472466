<template>
    <v-select :items="clusters" item-text="name" item-value="id" :readonly="readonly" :value="currentValue" label="Cluster"
        @change="onSelectCluster" return-object />
</template>

<script>
import { db } from "@/services/firebase";
import { collection, getDocs } from "firebase/firestore";

export default {
    name: "ClusterSelector",

    props: {
        readonly: Boolean,
        currentValue: String
    },

    data() {
        return {
            clusters: [],
            selectedCluster: null,
        };
    },

    created() {

        getDocs(collection(db, 'clusters')).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this.clusters.push({
                    id: doc.id,
                    ...doc.data()
                })
            });
        })
    },

    methods: {

        onSelectCluster(_cluster) {
            this.$emit('update:selectedCluster', _cluster);
        }
    }
}

</script>